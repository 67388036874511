<template>
  <div class="view pa24">
    <div class="d-flex">
      <div style="">
        <!-- <el-button class="ma mr10 mb10" @click="delAll">批量删除</el-button> -->
        <el-button class="ma" type="primary" @click="centerDialogVisible = true;upDate=1"
        >+ 添加角色
        </el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection"/>
        <el-table-column prop="roleName" align="center" label="角色名称"/>
        <el-table-column prop="createTime" align="center" label="创建时间"/>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="
                viewDetails(scope.row)
              "
            >功能分配
            </el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.dataId)"
                title="确认删除？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除
              </el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="添加角色"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item prop="title" label="角色名称">
          <el-input
              clearable
              v-model="ruleForm.title"
              style="width: 100%"
              placeholder="请输入角色名称"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="title" label="功能分配">
          <div class="" style="height: 400px;overflow: auto">
            <template v-if="navList != null && navList.length>0">
              <div class="item" v-for="(first,firIndex) in navList" :key="firIndex">
                <div class="oneCheck">
                  <el-checkbox :indeterminate="first.isIndeterminate" v-model="first.mychecked"
                               @change="firstCheck(firIndex,first.dataId)"
                               :label="first.title">
                    {{ first.title }}
                  </el-checkbox>
                </div>
                <div class="twoCheck" style="margin-left: 30px">
                  <div v-for="(second,secIndex) in first.childs" :key="secIndex">
                    <el-checkbox v-model="second.mychecked" @change="secondCheck(firIndex,second.fatherId)"
                                 :label="second.title">
                      {{ second.title }}
                    </el-checkbox>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {addOneRole,queryListLevelMenum,queryListPageRole,queryMemnumByRole,upateRole,deleteOneRole} from "@/api/settlement";

export default {
  name: "role",
  components: {
    commonTable,
    customPopconfirm
  },
  data() {
    return {
      centerDialogVisible: false,
      ruleForm: {
        title: "",
      },
      dialogVisible: false,
      rules: {
        title: [{required: true, message: "请输入角色名称", trigger: "blur"}],
      },
      tableData: [],
      currentPage: 1,
      loading: false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      upDate: 1,

      navList:[],
      actNavList:[],
      dataId:'',
    };
  },
  mounted() {
    this.queryPage();
    this.getNavList()
  },
  methods: {
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryListPageRole(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        for (let i in list) {
          list[i].createTime = this.renderTime(list[i].createTime)
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    async getNavList() {
      let data = {
        assIsMain: localStorage.getItem("assIsMain"),
        associationId: localStorage.getItem("associationId"),
      };
      try {
        const result = await queryListLevelMenum(data);
        let res = result.data
        for (var i = 0; i < res.length; i++) {
          res[i]['mychecked'] = false;//一级是否选中
          res[i]['isIndeterminate'] = false;//未全选但已经有了选项的状态
          if (res[i].childs) {
            for (var j = 0; j < res[i].childs.length; j++) {
              res[i].childs[j]['mychecked'] = false;//二级是否选中
            }
          }
        }
        console.log(res)
        this.navList = res;
      } catch (error) {
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    async viewDetails(row) {
      this.dataId = row.dataId
      this.upDate = 2
      let data = {
        dataId: row.dataId,
        assIsMain: localStorage.getItem("assIsMain"),
      };
      try {
        const result = await queryMemnumByRole(data);
        this.centerDialogVisible = true
        this.ruleForm.title = row.roleName
        let actNavList = result.data;
        let a = 0
        for (let k in actNavList) {
          for (var i = 0; i < this.navList.length; i++) {
            if (this.navList[i].childs) {
              if (actNavList[k].dataId == this.navList[i].dataId) {
                this.navList[i].mychecked = true
              }
              let childrenArray = this.navList[i].childs;
              let len = childrenArray.length;
              for (var j = 0; j < len; j++) {
                if (actNavList[k].dataId == childrenArray[j].dataId) {
                  childrenArray[j].mychecked = true
                  a ++
                }
              }

              // if (a == len) { //二级全勾选  一级勾选
              //   this.navList[i].mychecked = true;
              //   this.navList[i].isIndeterminate = false;
              // } else if (a == 0) { //二级全不勾选
              //   this.navList[i].isIndeterminate = false;
              //   this.navList[i].mychecked = false;
              // } else { //二级未全选  一级不勾选
              //   this.navList[i].mychecked = false;
              //   this.navList[i].isIndeterminate = false;
              // }

              if(this.navList[i].childs && this.navList[i].childs.length){
                let haveCheck = this.navList[i].childs.filter(v => v.mychecked);
                if(haveCheck.length && haveCheck.length == this.navList[i].childs.length){
                  this.navList[i].isIndeterminate = false;
                  this.navList[i].mychecked = true;
                }else if(haveCheck.length && haveCheck.length != this.navList[i].childs.length){


                  this.navList[i].isIndeterminate = true;
                  this.navList[i].mychecked = false;
                }else{
                  this.navList[i].isIndeterminate = false;
                  this.navList[i].mychecked = false;
                }
              }


            }else {
              if (actNavList[k].dataId == this.navList[i].dataId) {
                this.navList[i].mychecked = true
              }else{
                this.navList[i].mychecked = false
              }
            }
          }
        }
      } catch (error) {
      }
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await deleteOneRole({dataId: val});
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
      }),
          this.centerDialogVisible = false;
      for (var i = 0; i < this.navList.length; i++) {
        if (this.navList[i].childs) {
          let childrenArray = this.navList[i].childs;
          let len = childrenArray.length;
          for (var j = 0; j < len; j++) {
            childrenArray[j].mychecked = false
          }
          this.navList[i].mychecked == false
          this.navList[i].isIndeterminate == false
        }else {
          this.navList[i].mychecked == false
          this.navList[i].isIndeterminate == false
        }
      }
    },
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let checkedId = [];
            console.log(this.navList,'=====')
            for (var i = 0; i < this.navList.length; i++) {
              if (this.navList[i].childs) {
                if (this.navList[i].mychecked == true) {
                  checkedId.push(this.navList[i].dataId);
                }
                let childrenArray = this.navList[i].childs;
                let len = childrenArray.length;
                for (var j = 0; j < len; j++) {
                  if (childrenArray[j].mychecked == true) {
                    checkedId.push(childrenArray[j].dataId);
                  }
                }
              }else {
                if (this.navList[i].mychecked == true) {
                  checkedId.push(this.navList[i].dataId);
                }
              }
            };
            for (var i = 0; i < this.navList.length; i++) {
              if (this.navList[i].childs) {
                let haveCheck = this.navList[i].childs.filter(v => v.mychecked);
                if(haveCheck.length){
                  checkedId.push(this.navList[i].dataId)
                }
              }
            }
            let checkedIds = [...new Set(checkedId)]
            let data = {
              associationId: localStorage.getItem("associationId"),
              roleName: this.ruleForm.title,
              assRoleMenumReationIds:checkedIds.join(','),
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addOneRole(data);
            } else {
              data.dataId = this.dataId
              await upateRole(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    /* 一级选择事件 */
    firstCheck(index) {
      if (this.navList[index].mychecked == false) {
        this.navList[index].isIndeterminate = false;
        let childrenArray = this.navList[index].childs;
        if (childrenArray) {
          let len = childrenArray.length;
          for (var i = 0; i < len; i++) {
            childrenArray[i].mychecked = false;
          }
        }
      } else if (this.navList[index].mychecked == true) {
        this.navList[index].isIndeterminate = false;
        let childrenArray = this.navList[index].childs;
        if (childrenArray) {
          let len = childrenArray.length;
          for (var i = 0; i < len; i++) {
            childrenArray[i].mychecked = true;
          }
        }
      }
    },
    /* 二级选择事件 */
    secondCheck(index) {
      let childrenArray = this.navList[index].childs;
      console.log(childrenArray)
      let tickCount = 0,
          untickCount = 0,
          len = childrenArray.length;
      for (var i = 0; i < len; i++) {
        if (childrenArray[i].mychecked === true) {
          tickCount++;
        } else {
          untickCount++;
        }
      }
      if (tickCount == len) { //二级全勾选  一级勾选
        this.navList[index].mychecked = true;
        this.navList[index].isIndeterminate = false;
      } else if (tickCount == 0) { //二级全不勾选
        this.navList[index].isIndeterminate = false;
      } else { //二级未全选  一级不勾选
        this.navList[index].mychecked = false;
        this.navList[index].isIndeterminate = true;
      }
    },


  },
};
</script>

<style lang="scss" scoped></style>




